
table.reqform_table {
    border:none;
    float:left;
    width: 88%;
}


td.td_row_leftvalue {
   
    border:none;
    display:flex;
    float:left;
    width: 200px;
    vertical-align: middle;
    text-align: left;
    white-space: nowrap;
}

td.td_row_rightvalue {
    
    border:none;
    display:flex;
    float:right;

}

td.td_reason {
    border:none;
    display:flex;  
}

tr.reqForm_tablerow{
    border: none;
    display: flex;    
    align-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
    
}
.td.td_row_leftvalue {
    border:none;
    display:flex;
    float:left;
}
tr.app_page{
    border:none;
}

.icon-message-status-warning {
    color: red;
}